<template>
	<div class="hello">
		<b-container class="bv-example-row">
			<div class="cyfw">
				<div class="Tips">
					<span class="title">常用服务</span>
					<router-link to="/zxfw"><span class="more">更多<i
								class="el-icon-arrow-right"></i></span></router-link>
				</div>
				<el-dialog title="提示" :visible.sync="centerDialogVisible" width="350px" center>
					<div>
						<wxlogin :appid="appid" :scope="'snsapi_login'" :theme="'black'" :redirect_uri="redirect_uri"
							:href='bast64css' rel="external nofollow" class="ewm">
						</wxlogin>
					</div>
				</el-dialog>
				<b-row class="cyfw_box">
					<b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
						<router-link to="/grfj">
							<div>
								<img src="../assets/icon/复接.png" alt="">
								<span>个人复接申请</span>
							</div>
						</router-link>
					</b-col>
					<b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
						<router-link to="/bzsq">
							<div>
								<img src="../assets/icon/我要报装.png" alt="">
								<span>我要报装</span>
							</div>
						</router-link>
					</b-col>
					<b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
						<router-link to="/xxbg">
							<div>
								<img src="../assets/icon/信息变更.png" alt="">
								<span>个人信息变更</span>
							</div>
						</router-link>
					</b-col>
					<b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
						<router-link to="/xzbg">
							<div>
								<img src="../assets/icon/用书性质变更.png" alt="">
								<span>个人用水性质变更</span>
							</div>
						</router-link>
					</b-col>
					<b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
						<router-link to="/dwxxbg">
							<div>
								<img src="../assets/icon/信息变更-企业.png" alt="">
								<span>单位信息变更</span>
							</div>
						</router-link>
					</b-col>
					<b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
						<router-link to="/dwsbyb">
							<div>
								<img src="../assets/icon/水表验表-企业.png" alt="">
								<span>单位水表验表</span>
							</div>
						</router-link>
					</b-col>
				</b-row>
			</div>
			<b-row>
				<b-col sm="4">
					<div class="Tips">
						<span class="title">新闻中心</span>
						<router-link to="/xwzx"><span class="more">更多<i
									class="el-icon-arrow-right"></i></span></router-link>
					</div>
					<div>
						<b-col class="imgnone">
							<b-img alt="Image 1" fluid :src="imagelist.length > 0?imagelist[0].图片:images.searchpng"
								thumbnail></b-img>
						</b-col>
						<ul class="list">
							<li v-for="(item,index) in xwzx" :key="index">
								<router-link
									:to="{name: 'article', params: { id: item.ID }}">{{ item.标题 }}</router-link>
								<i>{{ item.发布时间 }}</i>
							</li>
						</ul>
					</div>
				</b-col>
				<b-col sm="4">
					<div class="Tips">
						<span class="title">停水公告</span>
						<router-link :to="{name: 'zwgk', params: { id: '34' }}"><span class="more">更多<i
									class="el-icon-arrow-right"></i></span></router-link>
					</div>
					<div>
						<b-col class="imgnone">
							<b-img alt="Image 1" fluid :src="imagelist.length > 1?imagelist[1].图片:images.searchpng"
								thumbnail></b-img>
						</b-col>
						<ul class="list">
							<li v-for="(item,index) in tsgg" :key="index">
								<router-link
									:to="{name: 'article', params: { id: item.ID }}">{{ item.标题 }}</router-link>
								<i>{{ item.发布时间 }}</i>
							</li>
						</ul>
					</div>
				</b-col>
				<b-col sm="4">
					<div class="Tips">
						<span class="title">水质公告</span>
						<router-link :to="{name: 'zwgk', params: { id: '35' }}"><span class="more">更多<i
									class="el-icon-arrow-right"></i></span></router-link>
					</div>
					<div>
						<b-col class="imgnone">
							<b-img alt="Image 1" fluid :src="imagelist.length > 2?imagelist[2].图片:images.searchpng"
								thumbnail></b-img>
						</b-col>
						<ul class="list">
							<li v-for="(item,index) in szgg" :key="index">
								<router-link
									:to="{name: 'article', params: { id: item.ID }}">{{ item.标题 }}</router-link>
								<i>{{ item.发布时间 }}</i>
							</li>
						</ul>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	import wxlogin from 'vue-wxlogin'
	export default {
		name: 'HelloWorld',
		props: {
			// msg: String
		},
		data() {
			return {
				pageNum: 1,
				pageSize: 5,
				xwzx: '',
				tsgg: '',
				szgg: '',
				images: {
					searchpng: require('../assets/bj.png'),
				},
				imagelist: [],
				User: null,
				centerDialogVisible: false,
				bast64css: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2hlaWdodDoyMDBweH0NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHh9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lfQ0KaWZyYW1lIHtoZWlnaHQ6IDMyMnB4O30NCg==',
				appid: 'wxe61e89a672036eae',
				redirect_uri: 'http://wx.xmadwater.com.cn/cs_y/andou/callBack',
			}
		},
		components: {
			wxlogin
		},
		async mounted() {
			await this.getImg();
			this.getxwzx();
			this.gettsgg();
			this.getszgg();
			this.User = localStorage.getItem('token');
		},
		methods: {
			async getxwzx() {
				let that = this
				const result = await this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章分页?页码=' + this
					.pageNum + '&最大条数=' + this.pageSize + '&所属=39');
				that.xwzx = result.data.记录
				// console.log(this.xwzx);
			},
			async getImg() {
				const res = await this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=48')
				if (res.data.length !== 0) {
					this.imagelist = res.data
					console.log(this.imagelist)
				}
			},
			async gettsgg() {
				let that = this
				const res = await this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章分页?页码=' + this
					.pageNum + '&最大条数=' + this.pageSize + '&所属=34');
				that.tsgg = res.data.记录
			},
			async getszgg() {
				let that = this
				const res = await this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章分页?页码=' + this
					.pageNum + '&最大条数=' + this.pageSize + '&所属=35');
				that.szgg = res.data.记录;
			},
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	h3 {
		margin: 40px 0 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.Tips {
		border-bottom: 1px solid #eee;
		padding: 1rem 0;
		display: flex;
		justify-content: space-between;

		.title {
			color: #333;
			font-size: 1rem;
			font-weight: bold;
		}

		.more {
			color: #999;
			font-size: 0.8rem;
		}
	}

	.col-sm {
		div {
			margin: 10px;
			height: 60px;
			width: 100%;
			background-color: #409eff;
		}
	}

	@media (max-width: 768px) {
		.imgnone {
			display: none;
		}
	}

	@media (min-width: 992px) {
		.imgnone {
			display: block;
		}
	}

	@media (min-width: 1200px) {
		.imgnone {
			display: block;
		}
	}

	ul li {
		margin: 0;
		list-style-type: none;
		display: block;
		text-align: initial;
		line-height: 20px;
	}

	.list {
		li {
			padding-bottom: 0.5rem;
			display: flex;
			justify-content: space-between;

			a {
				color: #333;
				font-size: 0.8rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: calc(100% - 133px);
			}

			i {
				font-style: normal;
				color: #999;
				font-size: 0.8rem;
				width: 133px;
			}
		}
	}

	.imgnone {
		padding: 1rem 0 !important;
		overflow: hidden;

		.img-thumbnail {
			height: 150px;
			width: 100%;
			cursor: pointer;
			transition: 0.6s;
			border: none !important;
			padding: 0 !important;
		}

		img:hover {
			transform: scale(1.1);
		}
	}

	.col-sm {
		div {
			width: auto;
		}
	}

	.cyfw_box {
		padding: 1rem 0;

		div {
			height: 80px;
		}
	}

	.cyfw {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.row {
		div {
			margin-bottom: 1rem;

			a {
				div {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;

					img {
						width: 60px;
						padding: 0.8rem;
					}

					span {
						color: #333;
						font-size: 0.8rem;
					}
				}
			}
		}
	}
</style>