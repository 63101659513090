import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/xwzx',
    name: 'xwzx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/xwzx.vue')
  },
  {
    path: '/ywly/:id',
    name: 'ywly',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ywly.vue')
  },
  {
    path: '/zwgk/:id',
    name: 'zwgk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/zwgk.vue')
  },
  {
    path: '/ddjs',
    name: 'ddjs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ddjs.vue')
  },
  {
    path: '/zxfw',
    name: 'zxfw',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/zxfw.vue')
  },
  {
    path: '/gyss/:id',
    name: 'gyss',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/gyss.vue')
  },
  {
    path: '/flsm',
    name: 'flsm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/flsm.vue')
  },
  {
    path: '/lxwm',
    name: 'lxwm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/lxwm.vue')
  }
  ,
  {
    path: '/jkcs',
    name: 'jkcs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/jkcs.vue')
  },
  {
    path: '/grfj',
    name: 'grfj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/grfj.vue'),
  },
  {
    path: '/grxh',
    name: 'grxh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/grxh.vue')
  },
  {
    path: '/xzbg',
    name: 'xzbg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/xzbg.vue')
  },
  {
    path: '/ztgs',
    name: 'ztgs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/ztgs.vue')
  },
  {
    path: '/xxbg',
    name: 'xxbg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/xxbg.vue')
  },
  {
    path: '/sbyb',
    name: 'sbyb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/sbyb.vue')
  },
  {
    path: '/ghsq',
    name: 'ghsq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/ghsq.vue')
  },
  {
    path: '/bzsq',
    name: 'bzsq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/bzsq.vue')
  },
  {
    path: '/qyfj',
    name: 'qyfj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/qyfj.vue')
  },
  {
    path: '/dwxh',
    name: 'dwxh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/dwxh.vue')
  },
  {
    path: '/dwysxz',
    name: 'dwysxz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/dwysxz.vue')
  },
  {
    path: '/dwztgs',
    name: 'dwztgs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/dwztgs.vue')
  },
  {
    path: '/dwxxbg',
    name: 'dwxxbg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/dwxxbg.vue')
  },
  {
    path: '/dwsbyb',
    name: 'dwsbyb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/dwsbyb.vue')
  },
  {
    path: '/dwghsq',
    name: 'dwghsq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/dwghsq.vue')
  },
  {
    path: '/article/:id',
    name: 'article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/article.vue')
  },
  {
    path: '/Login/:id',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.name !=='article'&&to.name !=='xwzx' && to.name !=='ywly'&& to.name !=='flsm'&& to.name !=='gyss'&& to.name !=='zxfw'&& to.name !=='ddjs'&& to.name !=='zwgk'&& to.name !=='Home'&& to.name !=='Login'){
    if(!localStorage.getItem('token')){
    //  跳转到登陆页面

    }else{
      next()
    }
  }else{
    next()
  }
})

export default router
