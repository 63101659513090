<template>
  <div id="app">
    <div class="erweima">
      <div>
        <img src="./assets/ewm.png" alt="">
        <span>扫码关注</span>
      </div>
    </div>
    <el-container>
      <el-header height="">
        <Nav />
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/Footer.vue'
import wxlogin from 'vue-wxlogin'

export default {

  name: 'App',
  components: {
    Nav,
    Footer,
    wxlogin
  },
  data() {
    return {
      bast64css: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2hlaWdodDoyMDBweH0NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHh9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lfQ0KaWZyYW1lIHtoZWlnaHQ6IDMyMnB4O30NCg==',
      appid: 'wxe61e89a672036eae',
      redirect_uri: 'http://wx.xmadwater.com.cn/cs_y/andou/callBack',
      ewmShow: false,
      signout: false,
      ID: '',
    }
  },
  watch:{
    $route(to,from){
      this.ID = localStorage.getItem('token');
      if (from.path === '/Login/' + this.ID) {
        setTimeout(() => {
          location.reload();
        }, 100)
      }else {
        console.log(from.path)
      }
    }
  },
  methods: {

  },
  mounted() {
    this.ID = localStorage.getItem('token');
    if (this.ID) {
      this.signout = false ;
    } else {
      this.signout = true;
      console.log(this.ID)
    }
  }

}
</script>


<style lang="less">
html, body {height:100%;}
ul, li {
  list-style-type: none;
  padding: 0;
}
.erweima{
  position: fixed;
  right: 16px;
  bottom: 150px;
  z-index: 10;
  width: 150px;
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      max-width: 100%;
    }
    span{
      color: #b5b5b5;
      font-size: 12px;
      line-height: 26px;
    }
  }
}
@media (max-width: 768px) {
  .minnone{
    display: none !important;
  }
}
a{text-decoration:none;color:#000;}
#app {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
.text_title{
  text-align: center;
}
.el-pagination{
  text-align: center;
}
a:hover{ text-decoration:none !important; }
.el-header{
  padding: 0 !important;
}
.el-footer{
  padding: 0 !important;
}
.el-main{
  padding: 0 !important;
}
.nav-link > a{
  display: block;
  width: 100%;
}
//.bitian > label{
//  color: #f00 !important;
//}
.dibu{
  //  字体大小1rem
  padding-bottom: 0.5rem;

  font-size: 0.8rem !important;
  p{
    padding: 0 !important;
    margin: 0 !important;
  }
}
.main_box >.container{
  padding: 2rem 0rem;
}
.media{
  padding-bottom: 1rem;
}
.active_box{
  .text_body{
    p{
      white-space: pre-wrap !important;
      img{
        width: 100%;
      }
    }
  }
}
.row{
  margin: 0 !important;
}
.box-card{
  margin-bottom: 1rem;
  .el-card__header{
    .clearfix:before {
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
  }
  .el-card__body{
    .bitian{
      label:before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
      }
    }

  }
}
form{
  .el-button{
    width: 100%;
  }
}

#input-group-1{
  div{
    .el-select{
      width: 100%;
    }
  }
}
.carousel-inner{
  .carousel-item{
    img{
      height: 400px !important;
    }
  }
  a{
    .carousel-item{
      img{
        height: 400px !important;
      }
    }
  }
}
</style>
