<template>
  <div id="footer">
    <div class="footer_top">
    </div>
    <template>
        <div class="footer">
          <div>
            <b-row>
              <b-col cols="6" md="3">
                <div class="footer-main" >
                  <h4>业务领域</h4>
                  <router-link :to="{name: 'ywly', params: { id: item.ID }}" v-for="(item,index) in ywly.二级" :key="index">{{item.名称}}</router-link>
                </div>
              </b-col>
              <b-col cols="6" md="3">
                <div class="footer-main">
                  <h4>企务公开</h4>
                  <router-link :to="{name: 'zwgk', params: { id: item.ID }}" v-for="(item,index) in qwgk.二级" :key="index">{{item.名称}}</router-link>
                </div>
              </b-col>
              <b-col cols="6" md="3">
                <div class="footer-main">
                  <h4>关于水司</h4>
                  <router-link :to="{name: 'gyss', params: { id: item.ID }}" v-for="(item,index) in gyss.二级" :key="index">{{item.名称}}</router-link>
                </div>
              </b-col>
              <b-col cols="6" md="3">
                <div class="footer-main">
                  <router-link to="/zxfw"><h4>在线服务</h4></router-link>
                  <router-link to="/xwzx"><h4>新闻中心</h4></router-link>
                </div>
              </b-col>
              <b-col cols="6" md="3">
                <div class="maxblock footer-main">
                  <h4>公众号</h4>
                  <div class="ewm">
                    <img :src="data.二维码 === ''?images.searchpng:data.二维码 " alt="">
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="minnone footer-main">
            <h4>公众号</h4>
            <div class="ewm">
              <img :src="data.二维码" alt="">
            </div>
          </div>
        </div>
    </template>
    <div >
        <div class="dibu" v-html="data.底部设置"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      //
      data:'',
      ywly:'',
      qwgk:'',
      gyss:'',
      images: {
        searchpng:require('../assets/ewm.png'),
      }
    }
  },
  mounted() {
    let that = this;
    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/查询网站设置')
        .then(function (response) {
          that.data = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });

    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/获取二级菜单')
        .then(function (response) {
          that.ywly = response.data[0]
          that.qwgk = response.data[1]
          that.gyss = response.data[3]
        })
        .catch(function (error) {
          console.log(error);
        });
  },
  methods: {
    //发送请求

  }

}
</script>

<style scoped lang="less">
#footer{
  background-color: #f3f3f3;
}
.footer{
  text-align: center;
  font-size: 16px;
  padding: 0px 100px;
  position: relative;
}
.copyright{
  font-size: 12px;
}
.info{
  font-size: 14px;
  color: #72767b;
  line-height: 25px;
}
.footer .scroll{
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border:2px solid #eaeaea;
  /* background-color: rgba(216, 16, 109,0.6); */
  position: absolute;
  left: 5%;
  top: -25px;
  z-index: 10;
  animation: scrollA infinite 20s linear alternate;
}
@media (max-width: 768px) {
  .footer_top {
    padding: 0 10px !important;
  }
  .minnone{
    display: none !important;
  }
  .maxblock{
    display: block !important;
  }
}
@media (min-width: 768px) {
  .minnone{
    display: block !important;
  }
  .maxblock{
    display: none !important;
  }
}

.footer_top{
  font-size: 0.8rem;
  max-width: 1140px;;
  background-color: #f3f3f3;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
  span{
    color: #333;
  }
}
.footer {
  box-sizing: border-box;
  width: auto;
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.footer-main {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
  margin-right: 50px;
  h4{
    font-size: 18px;
    color: #333;
    line-height: 1;
    margin: 0 0 15px;
  }
  a{
    display: block;
    margin: 0;
    line-height: 2;
    font-size: 14px;
    color: #666;
  }
}
.ewm{
  height: 10rem;
  width: 10rem;
  img{
    width: 100%;
  }
}
.dibu{
//  字体大小1rem
  font-size: 0.8rem !important;
  p{
    padding: 0 !important;
    margin: 0 !important;
  }
}
.row{
  div{
    margin-bottom: 1rem;
  }
}
.dibu /deep/ .ql-align-center{
  text-align: center;
}
.dibu /deep/ .ql-align-right{
  text-align: right;
}
.dibu /deep/ .ql-align-justify{
  text-align: justify;
}
.dibu /deep/ .ql-align-left{
  text-align: left;
}
</style>