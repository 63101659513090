<template>
	<div class="home">
		<Carousel>
			<b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" fade controls img-width="container"
				img-height="300">
				<a :href="item.跳转" target="_blank" v-for="(item,index) in Imglist" :key="index">
					<b-carousel-slide :img-src="item.图片"></b-carousel-slide>
				</a>

			</b-carousel>
			<div class="">
				<div class="alert-info1">
					<div class="notice__inner">
						<div class="notice__item" v-for="(item,index) in sygg" :key="index">
							<router-link :to="{name: 'article', params: { id: item.ID }}">{{ item.标题 }}</router-link>
						</div>
					</div>
				</div>
			</div>
		</Carousel>
		<HelloWorld />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Carousel from '@/components/Carousel.vue'
	import HelloWorld from '@/components/HelloWorld.vue'

	export default {
		name: 'Home',
		components: {
			HelloWorld,
			Carousel
		},
		data() {
			return {
				sygg: '',
				Imglist: []
			}
		},
		mounted() {
			document.documentElement.scrollTop = 0;
			let that = this
			this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章分页?页码=1&最大条数=4&所属=1').then(res => {
				that.sygg = res.data.记录
				// console.log(this.sygg);
			})

			this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=27').then(res => {
				console.log(res.data)
				this.Imglist = res.data
			})
		},
	}
</script>

<style lang="less">
	.container {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}

	.carousel_box,
	#carousel-fade___BV_inner_,
	#carousel-fade {
		max-height: 400px !important;
	}

	.carousel-inner,
	.carousel-item {
		max-height: 400px !important;
	}


	.alert-info1 {
		max-width: 1140px;
		;
		text-align: center;
		margin: auto;
		height: 20px;
		overflow: hidden;
		background-color: rgba(255, 255, 255, 0.9);
		border: none;
		position: relative;
		top: -20px;
		z-index: 9;
		border-color: rgba(255, 255, 255, 0.5);
		border-radius: 0;
	}

	.notice__inner {
		animation: roll 18s linear infinite;
		margin-top: 0
	}

	.notice__item {
		font-size: 14px;
		height: 20px;
		line-height: 20px;
		padding: 0 12px;
		white-space: nowrap;

		a {
			color: #333;
			text-decoration: none;
		}
	}

	.notice__item:hover {
		cursor: pointer;
	}

	@keyframes roll {
		0% {
			margin-top: 0;
		}

		8% {
			margin-top: 0;
		}

		16% {
			margin-top: -20px;
		}

		24% {
			margin-top: -20px;
		}

		32% {
			margin-top: -40px;
		}

		40% {
			margin-top: -40px;
		}

		48% {
			margin-top: -60px;
		}

		56% {
			margin-top: -60px;
		}

		64% {
			margin-top: -40px;
		}

		72% {
			margin-top: -40px;
		}

		80% {
			margin-top: -20px;
		}

		88% {
			margin-top: -20px;
		}

		100% {
			margin-top: 0;
		}
	}
</style>