<template>
  <div id="bynav">
    <b-navbar fixed="top" style="width:100%;" :class="[scrollTop === true?'myfixed':'']" toggleable="lg" type="dark" variant="dark">
      <div class="container">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <router-link to="/">
              <b-navbar-brand><img alt="安兜自来水有限公司" src="../assets/andoulogo.png" style="height: 40px">
            </b-navbar-brand>
            </router-link>
            <h4 class="fwrx">服务热线: <span>{{data.服务热线}}</span></h4>
            <!-- <b-nav-item href="#" disabled>xxx</b-nav-item> -->
          </b-navbar-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item>
                <span class="el-dropdown-link">
                  <router-link to="/">首页</router-link>
                </span>
            </b-nav-item>
            <b-nav-item>
                <span class="el-dropdown-link">
                  <router-link to="/xwzx">新闻中心</router-link>
                </span>
            </b-nav-item>
            <b-nav-item>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <router-link :to="{name: 'ywly', params: { id: '7' }}">业务领域</router-link>
                </span>
                <el-dropdown-menu slot="dropdown" >
                  <el-dropdown-item v-for="(val,key) in ywly.二级" :key="key" ><router-link :to="{name: 'ywly', params: { id: val.ID }}">{{val.名称}}</router-link></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </b-nav-item>
            <b-nav-item>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <router-link :to="{name: 'zwgk', params: { id: '34' }}">企务公开</router-link>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(val,key) in qwgk.二级" :key="key"><router-link :to="{name: 'zwgk', params: { id: val.ID }}">{{val.名称}}</router-link></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </b-nav-item>
            <b-nav-item>
                <span class="el-dropdown-link">
                  <router-link to="/zxfw">在线服务</router-link>
                </span>
            </b-nav-item>
            <b-nav-item>
              <el-dropdown >
                <span class="el-dropdown-link">
                  <router-link :to="{name: 'gyss', params: { id: '31' }}">关于水司</router-link>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(val,key) in gyss.二级" :key="key" ><router-link :to="{name: 'gyss', params: { id: val.ID }}">{{val.名称}}</router-link></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </b-nav-item>
            <b-nav-item v-show="!signout">
                <span class="el-dropdown-link" @click="remtoken">
                  <i class="el-icon-user-solid"></i>欢迎您 <i class="el-icon-d-arrow-right"></i>
                </span>
            </b-nav-item>
            <b-nav-item v-show="signout">
              <el-popover
                  placement="bottom-end"
                  trigger="hover">
                <div>
                  <wxlogin
                      :appid="appid"
                      :scope="'snsapi_login'"
                      :theme="'black'"
                      :redirect_uri="redirect_uri"
                      :href='bast64css'
                      rel="external nofollow"
                      class="ewm"
                  >
                  </wxlogin>
                </div>
                <span slot="reference">未登陆 <i class="el-icon-loading"></i></span>
              </el-popover>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <div class="min_navbar">

      <i class=" min_navbar_btn" @click="drawer = true"><b-icon icon="text-indent-left" aria-hidden="true"></b-icon></i>
      <el-drawer
          size="70%"
          title="快捷导航"
          :modal="false"
          :visible.sync="drawer"
          :with-header="false">
        <b-card no-body header="快捷导航" v-show="!caidanID" @click="caidan">
          <b-list-group flush>
            <router-link to="/"><b-list-group-item @click="caidanID = 0">首页</b-list-group-item></router-link>
            <router-link to="/xwzx"><b-list-group-item @click="caidanID = 0">新闻中心</b-list-group-item></router-link>
            <router-link :to="{name: 'ywly', params: { id: '7' }}"><b-list-group-item  @click="caidanID = 7">业务领域</b-list-group-item></router-link>
            <router-link :to="{name: 'zwgk', params: { id: '12' }}"><b-list-group-item @click="caidanID = 12">企务公开</b-list-group-item></router-link>
            <router-link to="/zxfw"><b-list-group-item @click="caidanID = 0">在线服务</b-list-group-item></router-link>
            <router-link :to="{name: 'gyss', params: { id: '17' }}"><b-list-group-item @click="caidanID = 17">关于水司</b-list-group-item></router-link>
          </b-list-group>
        </b-card>
        <b-card no-body header="快捷导航"  v-show="caidanID">
          <b-list-group flush >
            <router-link :to="{name: caidanname, params: { id: val.ID }}" v-for="(val,key) in caidanlist" :key="key" ><b-list-group-item >{{val.名称}}</b-list-group-item></router-link>
            <b-list-group-item @click="caidanID=0">返回菜单</b-list-group-item>
          </b-list-group>
        </b-card>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin'
export default {
  name: "bynav",
  data() {
    return {
      scrollTop:false,
      drawer: false,
      ywly:'',
      qwgk:'',
      gyss:'',
      data:'',
      caidanID:0,
      caidanlist:'',
      caidanname:'',
      ID:'',
      signout:false,
      bast64css: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2hlaWdodDoyMDBweH0NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHh9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lfQ0KaWZyYW1lIHtoZWlnaHQ6IDMyMnB4O30NCg==',
      appid: 'wxe61e89a672036eae',
      redirect_uri: 'http://wx.xmadwater.com.cn/cs_y/andou/callBack',
    };
  },
  components: {
    wxlogin
  },
  mounted(){
    let that = this
    window.addEventListener('scroll', this.handleScroll,true)
    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/获取二级菜单')
        .then(function (response) {
          console.log(response)
          that.ywly = response.data[0]
          that.qwgk = response.data[1]
          that.gyss = response.data[3]
        })
        .catch(function (error) {
          console.log(error);
        });

      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/查询网站设置')
          .then(function (response) {
            that.data = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    this.ID = localStorage.getItem('token');
    if (this.ID) {
      this.signout = false ;
    } else {
      this.signout = true;
      console.log(this.ID)
    }
  },
  methods: {
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;// 兼容
      scrollTop >= 5 ? this.scrollTop = true : this.scrollTop = false
    },
    toywly(id){
      console.log(id)
      this.router.push(`/ywly/id=${id}`)
    },
    caidan(){
      if (this.caidanID === 7){
        this.caidanlist = this.ywly.二级
        this.caidanname = 'ywly'
      }else if (this.caidanID === 12){
        this.caidanlist = this.qwgk.二级
        this.caidanname = 'zwgk'
      }else if (this.caidanID === 17){
        this.caidanlist = this.gyss.二级
        this.caidanname = 'gyss'
      }else{
        this.caidanlist = []
        this.caidanname = ''
      }
    },
    denglu(){

    },
    remtoken(){
      this.$confirm('请问是否退出当前登陆？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('token');
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        location.reload();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消退出'
        });
      });
    }
  },
}
</script>

<style lang="less" scoped>
.bg-dark {
  background-color: rgb(255,255,255) !important;
  //background-color: red !important;
}
.fixed-top{
  -webkit-transform: translateZ(0);
  position: inherit;
  transition: myfixed 2s;
}
.nav-link , .el-dropdown{
  color: #2c3e50 !important;
  font-size: 14px;
}
.navbar-dark , .navbar-toggler {
  padding: 1rem ;
  background-color:rgb(255,255,255);
}
.container {
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}
.el-dropdown-link > a{
  font-size: 1rem;
  color: #333;
}
@media (max-width: 992px) {
  .container {
    width: 750px;
  }
  .navbar{
    display: none !important;
  }
  .min_navbar{
    display: block;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .navbar{
    display: none !important;
  }
  .min_navbar{
    display: block;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .navbar{
    display: block !important;
  }
  .min_navbar{
    display: none;
  }
}

.navbar-brand {
  padding: 0;
}
.carousel_box , #carousel-fade___BV_inner_ , #carousel-fade{
  max-height: 400px !important;
}
.carousel-inner  , .carousel-item{
  max-height: 400px !important;
}
.alert{
  padding: 0.2rem 1.25rem !important;
}
.alert-info{
  width: 100%;
  margin: auto;
  background-color: rgba(255,255,255,0.9);
  border: none;
  position: relative;
  top: -48px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  border-color: rgba(255,255,255,0.5);
  border-radius:0;
}
.gsgg{
}
.myfixed{
  position: fixed;

  border-bottom: 1px solid #f3f3f3;
  box-shadow: 3px 3px 6px 3px rgba(222, 222, 222, .3);
}
.router-link-exact-active{
  color: var(--purple) !important;
}
.fwrx{
  color: #409eff;
  font-size: 1.2rem;
  span{
    color: red;
  }
}
.navbar-nav{
  align-items: flex-end;
}
.min_navbar_btn{
  position: fixed;
  height: 40px;
  width: 40px;
  top: 5px;
  left: 5px;
  z-index: 9999;
  font-size: 2rem;
}
.el-dropdown-menu__item > a{
  color: #2c3e50 ;
  display: block;
  width: 100%;
}
.list-group > a{
  color: #2c3e50 ;
  display: block;
  width: 100%;
}
</style>