<template>
  <div class="carousel_box">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "carousel"
}
</script>

<style scoped lang="less">

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.carousel_box , #carousel-fade___BV_inner_ , #carousel-fade{
  max-height: 400px !important;
}
.carousel-inner , .carousel-item{
  max-height: 400px !important;
}
.alert-info{
  width: 100%;
  margin: auto;
  background-color: rgba(255,255,255,0.9);
  border: none;
  position: relative;
  top: -48px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  border-color: rgba(255,255,255,0.5);
  border-radius:0;
}
</style>